import { get } from '@/utils/http/request'
import type { PayChannel } from './model/paymentModel'

export function getPayChannels() {
  return get<PayChannel[]>('org/payManager')
}

// 更爱状态
export function updateStatus(params: { id: number, enable: number }) {
  return get('org/payManager/enable', params)
}
