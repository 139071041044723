<template>
  <app-page>
    <el-table border :data="rows" v-loading="loading" class="mt2">
      <el-table-column
        v-for="item in fields"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :min-width="item.minWidth"
      />
      <el-table-column label="状态" :width="100">
        <template #="{ row }">
          <el-switch
            v-model="row.status"
            :active-value="1"
            :inactive-value="0"
            @change="handleStatusChange(row)"
          />
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" :width="100">
        <el-button type="primary" size="mini">编辑</el-button>
      </el-table-column> -->
    </el-table>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { getPayChannels, updateStatus } from '@/api/payment'
import type { PayChannel } from '@/api/model/paymentModel'

const fields = [
  { label: '编号', prop: 'id', minWidth: 50 },
  { label: '支付产品', prop: 'payProduct' },
  { label: '支付通道', prop: 'payChannel' },
  { label: '支付接口', prop: 'payInterface' },
  { label: '产品模式', prop: 'productModel' },
  { label: '适用银行', prop: 'applyBank' },
  { label: '供应商账户数', prop: 'serviceNum' },
  { label: '客户账户数', prop: 'customerNum' },
  { label: '累计流水', prop: 'flow' }
]

export default defineComponent({
  name: 'Payments',
  setup() {
    // 列表加载
    // ----------------------------
    const loading = ref<boolean>(false)
    const rows = ref<PayChannel[]>([])
    const loadList = async () => {
      loading.value = true
      const res = await getPayChannels()
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result
      }
    }
    loadList()

    // 状态更改
    // ----------------------------
    const handleStatusChange = (row: PayChannel) => {
      const { id, status } = row
      updateStatus({ id, enable: status }).then(res => {
        if (res.success) {
          ElMessage.success('修改成功')
        } else {
          row.status = status === 0 ? 1 : 0
        }
      })
    }

    return {
      loading,
      fields,
      rows,
      handleStatusChange
    }
  }
})
</script>
